import React, { useState, useEffect } from "react";
import images from "../../../Assets/Images";

export default function QuizPage({ lesson, lessonState }) {
  const [content, setContent] = useState();
  const [state, setState] = useState({});

  useEffect(() => {
    if (typeof lesson.content === "string") {
      let x = JSON.parse(lesson.content);
      if (!x.answer) {
        x.answer = false;
      }
      setContent(x);
    } else {
      if (!lesson.content.answer) {
        lesson.content.answer = false;
      }
      setContent(lesson.content);
    }
  
  
  }, []);
 
  const myResponse = (res) => {
    // console.log("dd")
    setState({ ...state, response: res });
    // if(res==="true" && content.answer)
    // {
    //   setState({...state,answer:true})
    // }else{
    //   setState({...state,answer:false})
    // }
    // lessonState({  lesson_step: true });
  };
  return (
    <div class="content_box">
      {content && (
        <>
          {content.title && <h3 class="title">{content.title}</h3>}
          {content.text && <p class="text">{content.text}</p>}
          {content.image && (
            <div class="img_box">
              <img src={content.image} alt="chapter" />
            </div>
          )}
          <div class="investing_radio_wrap">
            <div
              class={
                state.response === "true" && content.answer
                  ? "investing_radio red_get_value"
                  : "investing_radio"
              }
              onClick={() => myResponse("true")}
            >
              <div class="investing_text">
                <p>True</p>
              </div>
              <div class="investing_img">
                <img src={images["red_btn_img.png"]} alt="img" />
              </div>
            </div>
            <div
              class={
                state.response === "false" && content.answer
                  ? "investing_radio green_get_value"
                  : "investing_radio"
              }
              onClick={() => myResponse("false")}
            >
              <div class="investing_text">
                <p>False</p>
              </div>
              <div class="investing_img">
                <img src={images["green-mark.png"]} alt="img" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
