import React from 'react';
import images from "../../Assets/Images";
import { useNavigate, useLocation } from "react-router-dom";

export default function PageNotFound404() {
  const navigate = useNavigate();
  // const location =useLocation();
  return (
    <div class="pageNot_found_container">
      <div></div>
      <img src={images['page_not_found.svg']} alt="page_not_found" class="not_found_img" />
      <div>
        <h5 class="pageNot_found_heading">Ooops! Page not found!</h5>
        <p class="pageNot_found_pera">The page you are looking for might have been removed, had its name changed, or
          is
          temporarily
          unavailable.
        </p>
      </div>
      <button class="btn" onClick={() => navigate('/')}>Go back</button>

    </div>
  )
}
