
import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation, useSearchParams, matchPath } from "react-router-dom";
import Login from "../templates/auth/Login";
import Forgot from "../templates/auth/Forgot";
import Register from "../templates/auth/Register";
import Auth from "../helpers/Auth";
import ResetPassword from "../templates/auth/ResetPassword";

export default function GuestUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = Auth();
  // console.log(location);
  useEffect(() => {
    // console.log(location);
   
    const patterns = ["/auth/signin", "/auth/signup", "/auth/forgot", "/email-login", "/reset-password/:token"]
    const match = patterns.reduce(
      (match, pattern) => (match ? match : matchPath(pattern, location.pathname)),
      null
    );

    if (match !== null) {

      navigate(location.pathname);
    } else {
      navigate("/auth/signin");

    }

  }, [location.pathname]);

  const VerifyEmail = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("em");
    let token = searchParams.get("t");
    const { http } = Auth();
    // setParamToken(email);
    // console.log(email, token)
    if (email && token) {
      http
        .post("/user/verification", {
          email: email,
          verifyToken: token,
        }).then((res) => {
          if (res.data.status === 1) {
            navigate("/auth/signin");
          }
          // console.log(res.data)
        }).catch((err) => {
          console.log(err.response)
        })
    } else {

      navigate("/auth/signin");
    }

  }
  const EmailLogin = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("em");
    const { http, setToken } = Auth();
    // setParamToken(email);
    // console.log(email)
    if (email) {
      http
        .post("/user/login/email", {
          email: email,

        }).then((res) => {
          // console.log(res.data)
          setToken(
            {
              id: res.data.data.id,
              name: res.data.data.name,
              email: res.data.data.email,
              isplanActive: res.data.data.isplanActive,
              accesChatGPT: res.data.data.accesChatGPT
            },
            res.data.data.accessToken
          );
        }).catch((err) => {
          console.log(err.response)
        })
    } else {
      navigate("/auth/signin");
    }
  }
  return (

    <Routes>
      <Route path="/auth/signin" element={<Login />} />
      <Route path="/auth/signup" element={<Register />} />
      <Route path="/auth/forgot" element={<Forgot />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/email-login" element={<EmailLogin />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />

    </Routes>

  );
}

