import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import images from "../../Assets/Images";
import { restClient, websocketClient } from "@polygon.io/client-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Simulator() {
  const navigate = useNavigate();
  const rest = restClient("jqDrEG1TxWfFpliJqhrmWpMpBsgYv8f_");
  // const stocksWS = websocketClient("jqDrEG1TxWfFpliJqhrmWpMpBsgYv8f_").stocks();
  const [stocks, setStocks] = useState();

  useEffect(() => {
    // rest.stocks.aggregatesGroupedDaily("2023-01-01").then((data) => {
    //   console.log(data);
    // }).catch(e => {
    //   console.error('An error happened:', e);
    // });
    axios
      .get(
        `https://api.polygon.io/v2/aggs/grouped/locale/us/market/stocks/2023-01-09?adjusted=true`,
        {
          headers: { Authorization: "Bearer jqDrEG1TxWfFpliJqhrmWpMpBsgYv8f_" },
        }
      )
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.error("An error happened:", err);
      });
  }, []);

  return (
    <>
      <div className="main_container">
        <Header />
        <div className="chapter_box simulator_box">
          <div className="account_value_box">
            <ul>
              <li>Account value</li>
              <li>
                <p className="demo_tag">DEMO</p>
                <img src={images["question_mark.svg"]} alt="question" />
              </li>
            </ul>
            <div className="value_info_box">
              <h3>
                $<span>20,005.14</span>
              </h3>
              <p>+$5.14</p>
            </div>
            <div className="metrics_box">
              <div className="return_profit_box">
                <h5>Total return</h5>
                <p>+0.03%</p>
              </div>
              <div className="return_profit_box">
                <h5>Total profit/loss</h5>
                <p>+$5.14</p>
              </div>
            </div>
            <div className="btn_group trade_now_btn">
              <button className="btn_theme_1" onClick={()=>navigate("/simulator/buy-assets")}>
                <img src={images["plus.svg"]} alt="plus" />
                <span>TRADE NOW</span>
              </button>
            </div>
          </div>
          <div className="active_trads_box">
            <div className="active_trad_titleBar">
              <p>Active Trades</p>
              <span>3 items</span>
            </div>
          </div>
          <div className="asset_container">
            <ul>
              <li>
                <div className="info_box">
                  <div className="img_box">
                    <img src={images["appl_logo.png"]} alt="company_logo" />
                  </div>
                  <div className="content_box">
                    <p className="name">Apple Inc.</p>
                    <p className="ticker">2.179999 AAPL</p>
                  </div>
                </div>
                <div className="costs_box">
                  <p className="cost">$485.62</p>
                  <p className="pnl blue">-$8.25</p>
                </div>
              </li>
              <li>
                <div className="info_box">
                  <div className="img_box">
                    <img src={images["msft_logo.png"]} alt="company_logo" />
                  </div>
                  <div className="content_box">
                    <p className="name">Microsoft Corporation</p>
                    <p className="ticker">
                      <span>0.24</span> MSFT
                    </p>
                  </div>
                </div>
                <div className="costs_box">
                  <p className="cost">$102.70</p>
                  <p className="pnl green">+$2.43</p>
                </div>
              </li>
              <li>
                <div className="info_box">
                  <div className="img_box">
                    <img src={images["nvda_logo.png"]} alt="company_logo" />
                  </div>
                  <div className="content_box">
                    <p className="name">NVIDIA Corp</p>
                    <p className="ticker">5.76 NVDA</p>
                  </div>
                </div>
                <div className="costs_box">
                  <p className="cost">$686.13</p>
                  <p className="pnl red">+$9.27</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
