import React, { useEffect, useState } from "react";
import images from "../../Assets/Images";
import { useNavigate, useParams } from "react-router-dom";
import Auth from "../../helpers/Auth";

export default function BooksChapter() {
  const [state, setState] = useState({
    slide: false,
    step: 0,
  });
  const [chapter, setChapter] = useState();
  const [chapterContent, setChapterContent] = useState();
  const navigate = useNavigate();
  const { http } = Auth();
  const params = useParams();
  const closeChapter = () => {
    if (state.slide) {
      setState({ ...state, slide: false });
    } else {
      navigate(`/books`);
    }
  };
  useEffect(() => {
    http
      .get(`book-details-chapter/${params.book_id}`)
      .then((res) => {
        console.log(res);
        setChapter(res.data.data);
        setState({
          ...state,
          total_chapters: res.data.data.book_chapters.length,
        });
        setChapterContent(res.data.data.book_chapters[state.step]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.book_id]);
  const nextContent = () => {
    if (state.chapter_complete) return;
    let chapter_complete = false;
    if (state.step + 1 === state.total_chapters - 1) {
      chapter_complete = true;
    }
    //   console.log(changeBy , state.step,state.total_chapters,state.step + 1 === state.total_chapters - 1);
    setChapterContent(chapter.book_chapters[state.step + 1]);
    setState({
      ...state,
      step: state.step + 1,
      chapter_complete: chapter_complete,
    });
    console.log(state);
  };
  const prevContent = () => {
    console.log("sdsdf");
    // setChapterContent(chapter.book_chapters[state.step - 1]);
    setState({
      ...state,
      step: state.step - 1,
      chapter_complete: false,
    });
  };
  return (
    <div class="main_container">
      <div class="top_header_book_inner pop_up">
        <div class="header_book_inner_wrapper">
          <div class="book_inner_left_arrow" onClick={closeChapter}>
            <img
              src={images["book_inner_pop_up_cross.png"]}
              alt="img"
              class="img_fluid"
            />
          </div>
          <div class="book_header_text">
            <p>{chapter && chapter.name}</p>
          </div>
          <div
            class="book_inner_sideBar"
            onClick={() => setState({ ...state, slide: !state.slide })}
          >
            <img
              src={images["book_inner_pop_up_side_bar.png"]}
              alt="img"
              class="img_fluid"
            />
          </div>
        </div>
      </div>
      <div
        class="book_details_popUp"
        style={state.slide ? { opacity: 0 } : { opacity: 1 }}
      >
        <p class="book_details_popUp_head">
          {chapterContent && chapterContent.name}
        </p>
        <p class="book_details_popUp_para">
          {chapterContent && chapterContent.content.text}
        </p>

        <div class="pagination">
          <div class="page_arrow" onClick={prevContent}>
            <img
              src={images["arrow.svg"]}
              style={{ transform: "rotate(180deg)" }}
              alt=""
            />
          </div>
          <div class="page_number_div">
            <p class="page_number">
              <span class="pageIn">{state.step + 1}</span> of{" "}
              <span class="page_to">
                {state.total_chapters && state.total_chapters}
              </span>
            </p>
          </div>
          {state.chapter_complete ? (
            <div></div>
          ) : (
            <div class="page_arrow" onClick={nextContent}>
              <img src={images["arrow.svg"]} alt="" />
            </div>
          )}
        </div>
      </div>

      <div
        class="book_lessonList_popup"
        style={state.slide ? { opacity: 1 } : { opacity: 0 }}
      >
        <p class="book_details_top_text">What's inside?</p>
        {chapter &&
          chapter.book_chapters.length > 0 &&
          chapter.book_chapters.map((el) => {
            return (
              <div
                key={el.id}
                className="inside_box"
                onClick={() => navigate(`/books/${params.book_id}/chapter`)}
              >
                <div className="inside_box_number">
                  <p>{el.order_no}</p>
                </div>
                <div className="inside_box_text">
                  <p>{el.name}</p>
                </div>
                <div className="inside_box_image">
                  <img
                    src={images["inside_box_check_icon.png"]}
                    alt="img"
                    className="img_fluid"
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
