import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../helpers/Auth";
import PreLoader from "../loader/PreLoader";
import Loader from "../loader/Loader1";
export default function SubscriptionPlan() {
  const navigate = useNavigate();
  const [userSubscriptionPlan, setUserSubscriptionPlan] = useState([]);
  const [state, setState] = useState({
    modal: false,
    loader: false,
    isData: false,
  });

  const { http, user, errorHandle } = Auth();

  useEffect(() => {
    // console.log(user)
    http
      .get(`/user-subscription-plan/${user.id}`)
      .then((res) => {
        // console.log(res.data.data)
        setState({ ...state, isData: true });
        setUserSubscriptionPlan(res.data.data);
      })
      .catch((err) => {
        errorHandle(err);
        // console.log(err.message);
      });
  }, []);

  const cancelSubscription = () => {
    setState({ ...state, loader: true });
    http
      .post(`/user/cancel-subscription`, {
        customer_subscription_details_id:
          userSubscriptionPlan[0].customer_subscription_details_id,
        subscription_Id: userSubscriptionPlan[0].stripe_subscription_id,
        gw_mode: userSubscriptionPlan[0].is_test_data,
        email: user.email,
      })
      .then((res) => {
        console.log(res.data);
        setState({
          ...state,
          modal: true,
          loader: false,
          message: res.data.data.message,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
        setState({
          ...state,
          modal: true,
          loader: false,
          message: err.response.data.message,
        });
      });
    // console.log('ok')
  };

  // const reactiveSubscription = () => {
  //     setState({ ...state, loader: true });
  //     http.post(`/user/reactive-subscription`, {
  //         customer_subscription_details_id: userSubscriptionPlan[0].customer_subscription_details_id,
  //         subscription_Id: userSubscriptionPlan[0].stripe_subscription_id,
  //         gw_mode: userSubscriptionPlan[0].is_test_data,
  //         email: user.email

  //     }).then((res) => {
  //         console.log(res.data)

  //         setState({ ...state, modal: true, loader: false, message: res.data.data.message });
  //     }).catch((err) => {
  //         console.log(err.response.data)
  //         setState({ ...state, modal: true, loader: false, message: err.response.data.message });
  //     })
  // }

  const closeModal = () => {
    setState({ ...state, modal: false });
    window.location.reload();
  };

  return (
    <>
      {state.isData ? (
        <>
          {userSubscriptionPlan && userSubscriptionPlan.length > 0 ? (
            <div className="container" style={{ padding: "0px" }}>
              <div className="settings_section">
                <div className="container_large  padding-0">
                  <div className="profile_header">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/profile/settings");
                      }}
                    >
                      <div>
                        <i className="fa fa-angle-left"></i>
                      </div>
                    </Link>
                    <p>Subscription</p>
                    <div></div>
                  </div>
                </div>
                <div className="profile_body">
                  <div class="subcription_body">
                    <h4>My Plan</h4>
                    <div class="subcription_dtl_box">
                      <div class="subcription_header">
                        <h3>finiva subscription</h3>
                      </div>
                      <div class="subcription_body_content">
                        <ul>
                          <li>
                            <p>Plan name:</p>
                            <h5>{userSubscriptionPlan[0].plan_name}</h5>
                          </li>
                          <li>
                            <p>Billing period:</p>
                            <h5>
                              {userSubscriptionPlan[0].duration_days + "-days"}
                            </h5>
                          </li>

                          {userSubscriptionPlan[0].is_onetime_purchase === 0 ? (
                            <>
                              {" "}
                              <li>
                                <p>Subscription price:</p>
                                <h5>
                                  {"$" + userSubscriptionPlan[0].payment_total}
                                </h5>
                              </li>
                              <li>
                                <p>
                                  <strong>Subscription total:</strong>
                                </p>
                                <h5>
                                  {"$" + userSubscriptionPlan[0].payment_total}
                                </h5>
                              </li>
                            </>
                          ) : (
                            <>
                              {" "}
                              <li>
                                <p>
                                  {" "}
                                  <strong>Total price:</strong>
                                </p>
                                <h5>
                                  {"$" + userSubscriptionPlan[0].payment_total}
                                </h5>
                              </li>
                            </>
                          )}
                        </ul>

                        {userSubscriptionPlan[0].is_canceled === 1 && (
                          <>
                            <div class="subcription_cancele_box">
                              <i class="fa-solid fa-circle-info"></i>
                              <p>Subscription canceled</p>
                            </div>
                          </>
                        )}
                        {/* you will be billed amount / 12 weeks from date */}
                        {userSubscriptionPlan[0].is_canceled === 0 &&
                          userSubscriptionPlan[0].is_onetime_purchase === 0 && (
                            <h5>
                              You will be billed{" "}
                              {"$" + userSubscriptionPlan[0].recurring_price} /{" "}
                              {Math.round(
                                userSubscriptionPlan[0].duration_days / 7
                              )}{" "}
                              weeks from{" "}
                              {userSubscriptionPlan[0].subscription_end_date}
                            </h5>
                          )}
                        {userSubscriptionPlan[0].is_onetime_purchase === 1 && (
                          <h5>
                            Your plan valid till
                            {" " +
                              userSubscriptionPlan[0].subscription_end_date}
                          </h5>
                        )}
                      </div>
                    </div>
                    {userSubscriptionPlan[0].is_canceled === 0 &&
                      userSubscriptionPlan[0].is_onetime_purchase === 0 && (
                        <>
                          <button
                            class="btn reactivate_btn"
                            onClick={cancelSubscription}
                          >
                            Cancel Subscription
                          </button>
                        </>
                      )}
                    {(user.isplanActive === false ||
                      userSubscriptionPlan[0].is_canceled === 1) && (
                      <>
                        <div
                          class="subcription_footer"
                          style={{ marginTop: "17px" }}
                        >
                          <p>
                            We have cancelled your subscription and you will not
                            be charged in the future
                          </p>
                          <p>
                            You still have access to everything finiva
                            until your current subscription ends
                          </p>
                        </div>
                        <div>
                          {/* <a
                            href={
                              `https://survey.finiva.ai/reactivate/?rt=` +
                              btoa(
                                userSubscriptionPlan[0].stripe_subscription_id +
                                  "|" +
                                  userSubscriptionPlan[0].is_test_data +
                                  "|" +
                                  user.email
                              )
                            }
                            target="_blank"
                            class="btn reactivate_btn"
                            style={{ display: "block" }}
                          >
                            Reactivate Subscription
                          </a> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {state.modal === true && (
                <div
                  className="email_codCheck_poup"
                  onTouchStart={() => console.log("test")}
                  style={{ display: state.modal ? "block" : "none" }}
                >
                  <div className="email_codCheck_box">
                    <div className="Closeicone_box">
                      <i className="fa fa-close" onClick={closeModal}></i>
                    </div>

                    <p>{state.message}</p>
                    <div className="code_input_box">
                      <input
                        type="submit"
                        value="Ok"
                        className="btn Sign_inbtn"
                        onClick={closeModal}
                      />
                    </div>
                  </div>
                </div>
              )}
              {state.loader === true && <Loader />}
            </div>
          ) : (
            <div className="container" style={{ padding: "0px" }}>
              <div className="settings_section">
                <div className="container_large  padding-0">
                  <div className="profile_header">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/profile/settings");
                      }}
                    >
                      <div>
                        <i className="fa fa-angle-left"></i>
                      </div>
                    </Link>
                    <p>Subscription</p>
                    <div></div>
                  </div>
                </div>
                <div className="profile_body" style={{ marginTop: "88px" }}>
                  <div class="subcription_body">
                    <h3>You have no any plan</h3>
                    <div class="subcription_body_content">
                      {/* <a
                        href={
                          `https://survey.finiva.ai/reactivate/?rt=` +
                          btoa("" + "|" + "" + "|" + user.email)
                        }
                        target="_blank"
                        class="btn reactivate_btn"
                        style={{ display: "block" }}
                      >
                        Activate plan
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <PreLoader />
      )}
    </>
  );
}
