import React from "react";
import Header from "../component/Header";
import HeaderNav from "../component/HeaderNav";
import Footer from "../component/Footer";

export default function Challenges() {
  return (
    <>
      <div className="main_container">
        <Header />
        <HeaderNav />
        <div className="chapter_box">
          <div className="challenges_box">
            <h3>Investing challenge</h3>
            <ul>
              <li>
                <p className="tag tag_beginner">Beginner</p>
              </li>
              <li>
                <svg height="10" width="10" className="mx-2">
                  <circle
                    cx="4"
                    cy="4"
                    r="2"
                    strokeWidth="3"
                    fill="#6B7280"
                  ></circle>
                </svg>
              </li>
              <li>28 days</li>
            </ul>
            <div className="progress-bar">
              <div className="line"></div>
            </div>
            <div className="image_box">
              <img src="https://app.finiva.io/public/challenges/ab91e29a-c195-4acd-b3f3-57802c7b89d8.png" alt="" />
            </div>
            <div className="reward_box">
              <p>Reward for challenge:</p>
              <ul>
                <li>
                  <img src="img/coin.svg" alt="coin" />
                </li>
                <li>
                  $<span>10,000</span>
                </li>
              </ul>
            </div>
            <div className="btn_group">
              <button className="btn_theme_2">VIEW</button>
              <button className="btn_theme_1">JOIN NOW</button>
            </div>
          </div>
          <div className="challenges_comingSoon">
            <p>COMING SOON</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
