import React from 'react';
import images from '../../Assets/Images';
import { useNavigate } from 'react-router-dom';

export default function BuyAssets() {
    const navigate = useNavigate();
  return (
    <div class="main_container">
        <div class="top_header back_header" onClick={()=>navigate('/simulator')}>
            <div class="logo_box">
                <img src={images["left_ang.svg"]} class="img_fluid" alt="left_logo"/>
            </div>
            <div class="coin_store">
                <ul class="coin_store_list">
                    <li><img src={images["coin.svg"]} alt=""/></li>
                    <li>
                        <p>$<span>14,730</span></p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="chapter_box simulator_box">
            <div class="search_stock_box">
                <img src={images["search.svg"]} alt="search"/>
                <input type="search" placeholder="Search stocks, crypto"/>
            </div>
            <div class="stock_tabBox">
                <ul>
                    <li class="active">All</li>
                    <li>Popular</li>
                    <li>Stocks</li>
                    <li>Crypto</li>
                </ul>
            </div>

            <div class="asset_container">
                <ul>
                    <li>
                        <div class="info_box">
                            <div class="img_box">
                                <img src={images["img/appl_logo.png"]} alt="company_logo"/>
                            </div>
                            <div class="content_box">
                                <p class="name">Apple Inc.</p>
                                <p class="ticker">2.179999 AAPL</p>
                            </div>

                        </div>
                        <div class="costs_box">
                            <p class="cost">$485.62</p>
                            <p class="pnl blue">-$8.25</p>
                        </div>
                    </li>
                    <li>
                        <div class="info_box">
                            <div class="img_box">
                                <img src={images["img/msft_logo.png"]} alt="company_logo"/>
                            </div>
                            <div class="content_box">
                                <p class="name">Microsoft Corporation</p>
                                <p class="ticker"><span>0.24</span> MSFT</p>
                            </div>

                        </div>
                        <div class="costs_box">
                            <p class="cost">$102.70</p>
                            <p class="pnl green">+$2.43</p>
                        </div>
                    </li>
                    <li>
                        <div class="info_box">
                            <div class="img_box">
                                <img src={images["nvda_logo.png"]} alt="company_logo"/>
                            </div>
                            <div class="content_box">
                                <p class="name">NVIDIA Corp</p>
                                <p class="ticker">5.76 NVDA</p>
                            </div>

                        </div>
                        <div class="costs_box">
                            <p class="cost">$686.13</p>
                            <p class="pnl red">+$9.27</p>
                        </div>
                    </li>
                </ul>

            </div>
            <div class="not_matching">
                <img src={images["not_matchins.png"]} class="img_fluid" alt="not_matching"/>
                <p>No matching assets</p>
            </div>
        </div>
    </div>
  )
}
