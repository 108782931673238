import React from "react";

export default function Logo() {
  return (
    <>
      <div className="top_header">
        <div className="logo_box">
          <img src="img/logo.png" className="img_fluid" alt="" />
        </div>
    
      </div>
    </>
  );
}
