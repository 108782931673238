import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import HeaderNav from "../component/HeaderNav";
import Footer from "../component/Footer";
import Auth from "../../helpers/Auth";
import { useNavigate } from "react-router-dom";
export default function Books() {
  const { http, user } = Auth();
  const [books, setBooks] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    http
      .get(`/books`)
      .then((res) => {
        console.log(res.data.data);
        setBooks(res.data.data);
      })
      .catch((errr) => {
        console.log(errr);
      });
  }, []);

  return (
    <>
      <div className="main_container">
        <Header />
        <HeaderNav />
        {books && (
          <div className="chapter_box">
            <div className="books">
              <div className="courses_titleBar books_titleBar">
                <p>All Books</p>
              </div>

              <div className="courses_innerBox">
                {
                  books.map((el)=>{
                    return (
                      <div key={el.id} className="courses_box" onClick={()=>navigate(`/books/${el.id}`)}>
                      <div className="image_side">
                        <div className="img_box">
                          <img src={el.image} alt="courses" />
                        </div>
                      </div>
                      <div className="content_box">
                        <h4>{el.name}</h4>
                        <ul>
                          <li>{el.type}</li>
                          <li>
                            <svg height="10" width="10" className="mx-2">
                              <circle
                                cx="4"
                                cy="4"
                                r="2"
                                stroke-width="3"
                                fill="#6B7280"
                              ></circle>
                            </svg>
                          </li>
                          <li>{el.duration}</li>
                        </ul>
                        <p className="book_complete">COMPLETED</p>
                      </div>
                    </div>
                    )
                  })
                }
               
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
