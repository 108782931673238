import React from 'react';
import { Link, NavLink } from "react-router-dom";

export default function HeaderNav() {
  return (
    <div className="top_nav_box">
    <ul className="top_nav_list">
      <li className="">
        <NavLink to={'/'} >Home</NavLink>
      </li>
      <li className="">
        <NavLink to={'/courses'}>Courses</NavLink>
      </li>
      <li className="">
        <NavLink to={'/challenges'}>Challenges</NavLink>
      </li>
      <li className="">
        <NavLink to={'/books'}>Books</NavLink>
      </li>
     
    </ul>
  </div>
  )
}
