import React, { useState, useEffect, useMemo } from "react";
import images from "../../Assets/Images";
import Auth from "../../helpers/Auth";
import { useNavigate, useParams } from "react-router-dom";
import ContentPage from "./lesson_content_type/ContentPage";
import EntryPage from "./lesson_content_type/EntryPage";
import ChoicePage from "./lesson_content_type/ChoicePage";
import ExplationPage from "./lesson_content_type/ExplationPage";
import QuizPage from "./lesson_content_type/QuizPage";
import TextPage from "./lesson_content_type/TextPage";

export default function LessonContent() {
  const { http, user } = Auth();
  const params = useParams();
  const navigate = useNavigate();
  const [lessonContent, setLessonContent] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [state, setState] = useState({
    step: 0,
    progres_bar: 0,
    lesson_length: 0,
    guide_box: true,
    lesson_step: true,
  });

  const lessonStep = (changeBy) => {
    if (changeBy === "add_by_one" && state.step < state.lesson_length - 1) {
      let lesson_complete = false;
      if (state.step + 1 === state.lesson_length - 1) {
        lesson_complete = true;
      }
      setLessonContent(lessonData[state.step + 1]);
      setState({
        ...state,
        step: state.step + 1,
        progres_bar: state.progres_bar + state.fraction,
        lesson_complete: lesson_complete,
      });
    } else if (changeBy === "remove_by_one" && state.step > 0) {
      console.log(state);
      setLessonContent(lessonData[state.step - 1]);
      setState({
        ...state,
        step: state.step - 1,
        progres_bar: state.progres_bar - state.fraction,
        lesson_complete: false,
      });
    }
  };
  useEffect(() => {
    http
      .get(`/lesson-content/${params.lesson_id}`)
      .then((res) => {
        console.log(res);
        setLessonData(res.data.data.lesson_contents);
        setState({
          ...state,
          lesson_length: res.data.data.lesson_contents.length,
          progres_bar: Math.round(100 / res.data.data.lesson_contents.length),
          fraction: Math.round(100 / res.data.data.lesson_contents.length),
        });
        setLessonContent(res.data.data.lesson_contents[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div class="read_chapter_container">
      <div class="read_chapter_topBar">
        <div class="close" onClick={() => navigate("/")}>
          <img src={images["close.svg"]} alt="class" />
        </div>
        <div class="bars_track">
          <div
            class="bars_line"
            style={{ width: state.progres_bar + "%" }}
          ></div>
        </div>
      </div>

      <div class="read_chapter_inner">
        {state.guide_box ? (
          <div
            class="read_chapter_guideBox"
            onClick={() => setState({ ...state, guide_box: false })}
          >
            <div class="left_side">
              <p>
                Tap here to <br></br> go back
              </p>
            </div>
            <div class="right_side">
              <div>
                <img src={images["tap.svg"]} alt="tap" />
                <p>
                  Tap here to <br></br>
                  go forward
                </p>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div class="read_chapter_contentBox">
              <div class="read_chapter_item">
                {lessonContent.type === "entry_page" && (
                  <EntryPage lesson={lessonContent} />
                )}
                {lessonContent.type === "content_page" && (
                  <ContentPage lesson={lessonContent} />
                )}
                {lessonContent.type === "choice_page" && (
                  <ChoicePage lesson={lessonContent} />
                )}
                {lessonContent.type === "explanation_page" && (
                  <ExplationPage lesson={lessonContent} />
                )}

                {lessonContent.type === "quiz_page" && (
                  <QuizPage lesson={lessonContent} lessonState={setState} />
                )}
                {lessonContent.type === "text_page" && (
                  <TextPage lesson={lessonContent} />
                )}
                {/* <InnerContent content={lessonContent}/> */}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  top: "0px",
                  height: "100%",
                  width: "100%",
                  opacity: 0,
                }}
              >
                <div
                  style={{ width: "50%" }}
                  onClick={() => lessonStep("remove_by_one")}
                >
                  Previous
                </div>
                <div
                  style={{ width: "50%" }}
                  onClick={() => lessonStep("add_by_one")}
                >
                  Next
                </div>
              </div>
            </div>
            <div>
              {state.lesson_complete && (
                <button
                  class="finish_lesson_btn"
                  onClick={() =>
                    navigate(
                      `/courses/${params.course_id}/${params.chapter_id}/${params.lesson_id}/complete`
                    )
                  }
                >
                  FINISH LESSON
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
