import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, Switch } from "react-router-dom";
import Auth from "../../helpers/Auth";
import Images from "../../Assets/Images";

export default function Footer() {
  const { user } = Auth();
  const { pathname } = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(pathname.startsWith("/guides"));
  }, [pathname]);
  return (
    // <div className="navbar">
    //     <ul>
    //         <li>

    //             {
    //                 user.isplanActive ? (<NavLink to="/" className={active&&'active'} >
    //                     <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="back"><path id="Vector" d="M7 20.5L9 16.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path id="Vector_2" d="M17 20.5L15 16.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path id="Vector_3" d="M12 16.5V21.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path id="Vector_4" d="M3 6.5C3 5.39543 3.89543 4.5 5 4.5H19C20.1046 4.5 21 5.39543 21 6.5V14.5C21 15.6046 20.1046 16.5 19 16.5H5C3.89543 16.5 3 15.6046 3 14.5V6.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path id="Vector_5" d="M12 3.5V4.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path id="Vector_6" d="M7 8.5H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path id="Vector_7" d="M7 12.5H11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
    //                     <p>Guides</p>

    //                 </NavLink>) : (
    //                     <span>
    //                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#A6AAB3" d="M12 4c1.648 0 3 1.352 3 3v3H9V7c0-1.648 1.352-3 3-3Zm5 6V7c0-2.752-2.248-5-5-5S7 4.248 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1ZM6 12h12v8H6v-8Z"></path></svg>
    //                         <p style={{ color: "#a6aab3" }}>Guides</p>
    //                     </span>
    //                 )
    //             }

    //         </li>
    //         <li>
    //             {
    //                 user.isplanActive ? (<NavLink to="/challenges" >
    //                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                         <path fill="currentColor" stroke="currentColor" d="m15.054 8.804.146-.147v-1.35l2.6-2.6V6.7h1.993l-2.6 2.6h-1.35l-.147.146-2.295 2.295-.218.219.098.293c.015.046.019.1.019.247a1.3 1.3 0 1 1-1.3-1.3c.146 0 .2.004.247.02l.293.097.219-.218 2.295-2.295ZM8.747 4.647A8.5 8.5 0 0 1 12 4c.715 0 1.427.094 2.12.273l-.67.67A7.463 7.463 0 0 0 12 4.8a7.7 7.7 0 1 0 7.7 7.7c0-.486-.047-.971-.142-1.45l.665-.666c.183.688.277 1.4.277 2.116A8.5 8.5 0 1 1 8.747 4.647Zm-.212 4.388a4.9 4.9 0 0 1 2.965-1.41v.806A4.1 4.1 0 1 0 16.07 13h.804a4.9 4.9 0 1 1-8.339-3.965Z"></path>
    //                     </svg>
    //                     <p>Challenges</p>
    //                 </NavLink>) : (
    //                     <span>
    //                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#A6AAB3" d="M12 4c1.648 0 3 1.352 3 3v3H9V7c0-1.648 1.352-3 3-3Zm5 6V7c0-2.752-2.248-5-5-5S7 4.248 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1ZM6 12h12v8H6v-8Z"></path></svg>
    //                         <p style={{ color: "#a6aab3" }}>Challenges</p>
    //                     </span>
    //                 )
    //             }

    //         </li>
    //         <li>{
    //             user.isplanActive ? (
    //                 <NavLink to="/ai-bots" >
    //                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                         <path d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V17C22 18.1046 21.1046 19 20 19H15.4142L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L8.58579 19H4C2.89543 19 2 18.1046 2 17V6ZM20 6H4V17H9C9.26522 17 9.51957 17.1054 9.70711 17.2929L12 19.5858L14.2929 17.2929C14.4804 17.1054 14.7348 17 15 17H20V6ZM6 9.5C6 8.94772 6.44772 8.5 7 8.5H17C17.5523 8.5 18 8.94772 18 9.5C18 10.0523 17.5523 10.5 17 10.5H7C6.44772 10.5 6 10.0523 6 9.5ZM6 13.5C6 12.9477 6.44772 12.5 7 12.5H13C13.5523 12.5 14 12.9477 14 13.5C14 14.0523 13.5523 14.5 13 14.5H7C6.44772 14.5 6 14.0523 6 13.5Z" fill="currentColor"></path>
    //                     </svg>
    //                     <p>AI Bots</p>
    //                 </NavLink>) : (
    //                 <span>
    //                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#A6AAB3" d="M12 4c1.648 0 3 1.352 3 3v3H9V7c0-1.648 1.352-3 3-3Zm5 6V7c0-2.752-2.248-5-5-5S7 4.248 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1ZM6 12h12v8H6v-8Z"></path></svg>
    //                     <p style={{ color: "#a6aab3" }}>AI Bots</p>
    //                 </span>
    //             )
    //         }
    //         </li>
    //         <li>
    //             <NavLink to="/profile">
    //                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4ZM6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8ZM8 18C6.34315 18 5 19.3431 5 21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21C3 18.2386 5.23858 16 8 16H16C18.7614 16 21 18.2386 21 21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21C19 19.3431 17.6569 18 16 18H8Z" fill="currentColor"></path>
    //                 </svg>
    //                 <p>Profile</p>
    //             </NavLink>
    //         </li>
    //     </ul>
    // </div>
    <div className="footer_nav">
      <ul>
        <li className="">
        <NavLink to={'/'}>
          <div className="img-box">
            <img src={Images["icon1.svg"]} alt="icon1" />
          </div>
          <div className="content_box">
            <p>Courses</p>
          </div>
          </NavLink>
        </li>
        <li>
        <NavLink to="/chat">
          <div className="img-box">
            <img src={Images["icon2.svg"]} alt="icon1" />
          </div>
          <div className="content_box">
            <p>Chat</p>
          </div>
          </NavLink>
        </li>
        <li>
        <NavLink to="/simulator">
          <div className="img-box">
            <img src={Images["icon3.svg"]} alt="icon1" />
          </div>
          <div className="content_box">
            <p>Simulator</p>
          </div>
          </NavLink>
        </li>
        <li>
        <NavLink to="/leaderboard">
          <div className="img-box">
            <img src={Images["icon4.svg"]} alt="icon1" />
          </div>
          <div className="content_box">
            <p>Leaderboard</p>
          </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/profile">
            <div className="img-box">
              <img src={Images["icon5.svg"]} alt="icon1" />
            </div>
            <div className="content_box">
              <p>Profile</p>
            </div>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
