import React from 'react';
import images from '../../Assets/Images';

export default function Header() {
  return (
  
          <div className="top_header">
          <div className="logo_box">
            <img src={images["logo.png"]} className="img_fluid" alt="" />
          </div>
          <div className="coin_store">
            <ul className="coin_store_list">
              <li>
                <img src={images["coin.svg"]} alt="" />
              </li>
              <li>
                <p>
                  $<span>14,730</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
 
  )
}
