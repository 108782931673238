import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import HeaderNav from "../component/HeaderNav";
import Images from "../../Assets/Images";
import Auth from "../../helpers/Auth";
import { Link, useParams } from "react-router-dom";

export default function Home() {
  const [courses, setCourse] = useState();
  const params = useParams();
  const { http, user } = Auth();
  const [state, setState] = useState({
    chapterToogle: false,
  });
  let course_id = 1;

  useEffect(() => {
    if (params.course_id) {
      http
        .get(`/course-chapter-lesson/${params.course_id}/${user.id}`)
        .then((res) => {
          console.log(res);
          // setCourse(res.data.data[0].chapters);
          setCourse(res.data.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      http
        .get(`/course-chapter-lesson/${course_id}/${user.id}`)
        .then((res) => {
          console.log(res);
          // setCourse(res.data.data[0].chapters);
          setCourse(res.data.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const toogleChapterPopUp = (activeLesson) => {
    setState({ ...state, chapterToogle: activeLesson });
  };

  return (
    <>
      <div className="main_container">
        <Header />
        <HeaderNav />
        <div className="chapter_box">
          <div className="chapter_fixed_box">
            <div className="left_content">
              <p className="title">The Investment Landscape</p>
              <p className="sub_title">Chapter 2</p>
            </div>
            <div className="right_box">
              <div className="img_box">
                <img src={Images["book.svg"]} alt="book" />
              </div>
            </div>
          </div>
          {courses &&
            courses.chapters.map((el, i) => {
              return (
                <div key={el.id} className="main_chapter_box">
                  <div className="chapter_title_box">
                    <div className="chapter_title_content">
                      <p>{`Chapter` + el.order_no}</p>
                      <h5>{el.name}</h5>
                    </div>
                    <div className="line"></div>
                  </div>

                  <div className="step-container">
                    <div className="step_inner">
                      {el.lessons.map((lesson, j) => {
                        let activeLesson = i + "" + j;
                        return (
                          <div
                            key={lesson.id + j}
                            className="step_box"
                            onClick={() => toogleChapterPopUp(activeLesson)}
                          >
                            <div className="step_img_box">
                              {/* <div className="start_tooltip">
                                <p>Start</p>
                              </div> */}
                              {state.chapterToogle &&
                                state.chapterToogle === activeLesson && (
                                  <div
                                    className="chapter_popup"
                                    style={{ display: "block" }}
                                  >
                                    <h5>{lesson.name}</h5>
                                    <ul>
                                      <li>{"Chapter " + el.order_no}</li>
                                      <li>
                                        <svg
                                          height="10"
                                          width="10"
                                          className="mx-2 opacity-80"
                                        >
                                          <circle
                                            cx="4"
                                            cy="4"
                                            r="2"
                                            strokeWidth="3"
                                            fill="#6B7280"
                                          ></circle>
                                        </svg>
                                      </li>
                                      <li>{"Lesson " + lesson.order_no}</li>
                                    </ul>
                                    <button>
                                      <Link
                                        to={`/courses/${courses.id}/${el.id}/${lesson.id}`}
                                        style={{ color: "white" }}
                                      >
                                        Start Lesson
                                      </Link>
                                    </button>
                                  </div>
                                )}
                              <img
                                src={Images["step_complete.svg"]}
                                alt="complete"
                              />
                            </div>
                            <div className="step_line_box">
                              <div className="line"></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}

          <div className="next_chapter_box">
            <p>Next course</p>
            <h5>Exploring the Stock Market</h5>
            <ul>
              <li>Understanding Stocks</li>
              <li>Trading </li>
              <li>Market Analysis</li>
            </ul>
            <div className="btn_group">
              <button className="btn_theme_1">Next course</button>
              <button className="btn_theme_2">Learning history</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
