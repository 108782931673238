import React, { useEffect, useState } from "react";
import images from "../../Assets/Images";
import { useNavigate, useParams } from "react-router-dom";
import Auth from "../../helpers/Auth";

export default function BooksInfo() {
  const navigate = useNavigate();
  const [bookDetails, setbookDetails] = useState();
  const params = useParams();
  const { http } = Auth();
  useEffect(() => {
    http
      .get(`book-details/${params.book_id}`)
      .then((res) => {
        console.log(res);
        setbookDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="main_container">
      <div className="top_header_book_inner">
        <div className="header_book_inner_wrapper">
          <div
            className="book_inner_left_arrow"
            onClick={() => navigate("/books")}
          >
            <img src={images["book_inner_arrow.png"]} alt="img" />
          </div>
          <div className="book_header_text">
            <p>Your Money or Your Life</p>
          </div>
          <div></div>
        </div>
      </div>
      <div className="book_inner_top_img_div">
        <div className="book_inner_top_img">
          <img src={bookDetails && bookDetails.image} alt="img" />
        </div>
      </div>
      <div className="book_inner_bottom_div">
        <div className="book_inner_desc">
          <p className="book_inner_desc_head">
            {bookDetails && bookDetails.name}
          </p>
          <p className="book_inner_desc_para">
            {bookDetails && bookDetails.description}
          </p>
          <div className="complete_div">
            <p>COMPLETED</p>
          </div>
          <div className="read_more_btn">
            <button type="button">Read Now</button>
          </div>
        </div>
        <div className="book_inner_divider">
          <hr></hr>
        </div>
        <div className="book_details_div">
          <p className="book_details_top_text">Book Details</p>
          <div className="book_details_total">
            <div className="book_details_box top_details_box">
              <div className="details_box_top_text">
                <p>Author</p>
              </div>
              <div className="details_box_bottom_wrap">
                <div className="details_box_left_img">
                  <img
                    src={images["admin_img.png"]}
                    alt="img"
                    className="img_fluid"
                  />
                </div>
                <div className="details_box_text">
                  <p>{bookDetails && bookDetails.author}</p>
                </div>
              </div>
            </div>
            <div className="book_details_box bottom_details_box">
              <div className="details_box_top_text">
                <p>Duration</p>
              </div>
              <div className="details_box_bottom_wrap">
                <div className="details_box_left_img">
                  <img
                    src={images["duration_img.png"]}
                    alt="img"
                    className="img_fluid"
                  />
                </div>
                <div className="details_box_text">
                  <p>{bookDetails && bookDetails.duration}</p>
                </div>
              </div>
            </div>
            <div className="book_details_box bottom_details_box">
              <div className="details_box_top_text">
                <p>Genre</p>
              </div>
              <div className="details_box_bottom_wrap">
                <div className="details_box_left_img">
                  <img
                    src={images["genre_img.png"]}
                    alt="img"
                    className="img_fluid"
                  />
                </div>
                <div className="details_box_text">
                  <p>{bookDetails && bookDetails.type}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="key_skill_div">
          <p className="book_details_top_text">Key skills</p>
          <div className="key_skill_wrapper">
            {bookDetails &&
              JSON.parse(bookDetails.skills).map((el, index) => {
                return (
                  <div key={index} className="key_skill_item">
                    <p>{el}</p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="inside_div">
          <p className="book_details_top_text">What's inside?</p>
          {bookDetails &&
            bookDetails.book_chapters.length > 0 &&
            bookDetails.book_chapters.map((el) => {
              return (
                <div
                  className="inside_box"
                  onClick={() =>
                    navigate(`/books/${params.book_id}/chapter`)
                  }
                >
                  <div className="inside_box_number">
                    <p>{el.order_no}</p>
                  </div>
                  <div className="inside_box_text">
                    <p>{el.name}</p>
                  </div>
                  <div className="inside_box_image">
                    <img
                      src={images["inside_box_check_icon.png"]}
                      alt="img"
                      className="img_fluid"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
