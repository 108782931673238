import React, { useState,useEffect } from 'react'

export default function ExplationPage({lesson}) {
    const [content, setContent] = useState();

  useEffect(() => {
    if (typeof lesson.content === "string") {
      setContent(JSON.parse(lesson.content));
    } else {
      setContent(lesson.content);
    }
  }, []);
  console.log(lesson.type, content);
  return (
    <div class="content_box">
    {content && (
      <>
        {content.title && <h3 class="title">{content.title}</h3>}
        {content.text && <p class="text">{content.text}</p>}
        {content.image && (
          <div class="img_box">
            <img src={content.image} alt="chapter" />
          </div>
        )}
      </>
    )}
  </div>
  )
}
